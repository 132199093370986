.MuiPagination-root {
    button {
        border: 1px solid #D0D5DD;
        color: #344054;

        &:hover {
            background-color: #F9FAFB !important;
        }
    }

    .Mui-selected {
        background-color: #EAECF0 !important;
    }
}

.MuiPagination-ul> :first-child>button {
    border-radius: 8px 0px 0px 8px !important;
}

.MuiPagination-ul> :last-child>button {
    border-radius: 0px 8px 8px 0px !important;
}

$regular-dark: #101828;

.center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-modal-title-header {
    color: $regular-dark !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: bold !important;
    line-height: 28px !important;
}
  
.delete-modal-body {
    margin: 32px 0px;
}
  
.delete-modal-body-title {
    color: #667085 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: -0.14px !important;
}
  
.delete-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
  
.delete-modal-button {
    border-radius: 16px !important;
    color: #fff !important;
    background-color: $regular-dark !important;
    border-color: #d0d5dd !important;
    text-transform: capitalize !important;
    box-shadow: none !important;
}

.close-modal-button {
    border-radius: 16px !important;
    color: #000 !important;
    border-color: #000 !important;
    text-transform: capitalize !important;
    box-shadow: none !important;
}
  
.delete-modal-button:hover {
    background-color: #ffffff !important;
    color: $regular-dark !important;
}
  
.delete-modal-button:disabled {
    color: #d0d5dd !important;
    background-color: #f2f4f7 !important;
}

.close-modal-button:hover {
    background-color: #000 !important;
    color: #ffffff !important;
}
  
.close-modal-button:disabled {
    color: #d0d5dd !important;
    background-color: #f2f4f7 !important;
}
  
.delete-modal-wrapper {
    width: 400px;
    background: white;
    padding: 24px;
    border-radius: 21px;
}
  