.CustomImage {
    margin: auto;
    margin-top: 1rem;
    height: 8rem;
    user-select: none;
}

.CustomDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.centerModal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgotButton {
    justify-content: flex-start;
    height: 10px;
    padding: 10px 3px;
    background: transparent;
    color: #667085;
    text-decoration: underline;
    border: none;
    width: 100%;
}