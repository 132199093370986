.modal {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 30px 24px 24px 24px;
    gap: 5px;
    width: 498px;
    user-select: none;
    background: #FFFFFF;
    border-radius: 40px;
}