@font-face {
  font-family: ft-system-bold;
  src: url(./assets/fonts/ft-system/bold.otf);
}

@font-face {
  font-family: ft-system-regular;
  src: url(./assets/fonts/ft-system/regular.otf);
}

:root {
  --border: solid 1px #D0D5DD !important
}

.displayFlex {
  display: flex;
}

.displayColumn {
  flex-direction: column;
}

.alignCenter {
  align-items: center;
}

.spaceBetween {
  justify-content: space-between;
}

.justifyContent {
  justify-content: center;
}

.justifyContentStart {
  justify-content: flex-start;
}

.justifyContentEnd {
  justify-content: flex-end;
}

.tableSection {
  background-color: #c5c5c5;
  border-collapse: collapse;
  width: 100%;
}

.tableSection>tbody>tr>td {
  padding-left: 5px;
}

.tdWhite {
  background-color: white;
}

.tdEnd {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.btnWhite {
  color: #101828 !important;
  background: #FFFFFF !important;
  border: var(--border);
  font-weight: bold;
}

.titleText {
  font-size: 40px !important;
  font-family: ft-system-bold !important;
}

.regularText {
  font-size: 18px !important;
}

.tinyText {
  font-size: 14px !important;
  color: #667085 !important;
  font-family: ft-system-regular !important;
}

.section {
  margin-top: 24px !important;
}

.sectionBody{
  margin: 15px 0
}

.footer {
  border-top: var(--border);
  padding-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.flex>div {
  width: 45%;
  padding: 2%;
  border: var(--border);
  border-radius: 0.5rem;
}

.block {
  width: 100%;
}

.progressStyle{
  width: 80%;
  position: relative;
  margin: auto 0;
}
.progressStyle>span{
  background-color: #f6eaf7;
  height: 8px;
  border-radius: 5px;
}
.progressStyle>span>span{
  background-color: #A82AAB;
}