.settings {
    width: 100%;
    display: flex;
    position: absolute;
    margin-top: 5%;
    height: 90%;
}

.settingsTabs {
    margin-top: 1em;
    width: 20%;
    border-color: #EAECF0 !important;
    border-right: 1;
}

.settingsTitle{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1rem 0px 1rem 1.5rem!important;
    color: #344054;
}

.sectionButtons{
    display: flex;
    justify-content: flex-end;
}