.custom-tab {
    text-transform: capitalize!important;
    font-size: 16px!important;
    margin: 5px 20px !important;
    border-radius: 6px!important;
    min-height: 50px !important;
    height: 50px;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    flex-direction: row;
    text-align: left !important;
    color: #344054;
    letter-spacing: -0.01em;

    &:hover {
        background: #F2F4F7 !important;
        color: #101828;
        font-weight: 600;
        opacity: 1;
    }
}

.activeTab {
    background: #F2F4F7 !important;
    color: #101828;
    font-weight: 600!important;
    opacity: 1!important;

    img path {
        stroke: #101828;
    }

    &:hover {
        cursor: initial;
    }
}