.CustomImage {
    margin: auto;
    margin-top: 1rem;
    height: 8rem;
    user-select: none;
}

.CustomDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.centerModal{
    display: flex;
    align-items: center;
    justify-content: center;
}