.planManagement {
    thead {
        tr>th {
            width: calc(100% / 7) !important;
        }
    }

    tbody {
        tr>th {
            width: calc(100% / 7) !important;
        }
    }
}

.pagination {
    ul {
        li {
            button {
                border-radius: 0;
                margin: 0;
            }
        }

        li:first-child {
            button {
                border-radius: 4px 0px 0px 4px;
            }
        }

        li:last-child {
            button {
                border-radius: 0px 4px 4px 0px;
            }
        }
    }
}