.CustomButton {
    display: flex;
    font-family: 'ft-system-regular';
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 24px 14px;
    gap: 10px;
    // width: 100%;
    height: 48px;
    background: #101828;
    border-radius: 20px;
    flex: none;
    order: 4;
    flex-grow: 0;
    color: #fff;
    cursor: pointer;
}

.CustomButton::first-letter {
    text-transform: capitalize;
}

.CustomButton :hover {
    background-color: #ffffff !important;
    color: #101828 !important;
}

.CustomButton :disabled {
    background: transparent;
    user-select: none;
    cursor: initial;
}

.disabled,
.disabled :hover {
    background: #F2F4F7 !important;
    color: #D0D5DD !important;
    border: #f2f4f7 !important;
    cursor: default;
}

.disabled>svg>path {
    stroke: #D0D5DD !important;
}

.logOut {
    padding: 0px !important;
    width: 100% !important;
    border: none !important;
}

.logOut :hover {
    background-color: transparent !important;
}

.transparent {
    border: solid 0px transparent !important;
    background: transparent !important;
}